"use client";
import { useEffect } from "react";
import { FaCircleArrowUp } from "react-icons/fa6";

export default function ScrollToTop() {
  useEffect(() => {
    // Function to handle scroll event
    function handleScroll() {
      // Get the current scroll position
      var scrollPosition = window.scrollY;

      // Get the height of the viewport (100vh)
      var viewportHeight = window.innerHeight;

      // Find the element with the id "scrollArrow"
      const scrollArrow = document.getElementById("scrollArrow");

      // Add the "hidden" class to the element

      // Check if the user has scrolled 100vh
      if (scrollPosition >= viewportHeight) {
        scrollArrow?.classList.remove("hidden");
      } else {
        scrollArrow?.classList.add("hidden");
      }
    }

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return <div
    onClick={scrollToTop}
    id="scrollArrow"
    className="hidden cursor-pointer arrowUp fixed right-[15px] bottom-[20%] z-[5]"
  >
    <FaCircleArrowUp size={30} color={"#000000"} />
  </div>
}
